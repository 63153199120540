import React from "react"

export default function Feature() {
	return (
		<div className="mt-24 container mx-auto px-4">
			<div className="mb-16 mx-auto text-center">
				<span className="text-xs font-light text-gray-500 uppercase">
					Discover what sets xpressfirst apart
				</span>
				<h2 className="mt-2 text-5xl text-center text-gray-900 md:text-7xl">
					Why choose us
				</h2>
				<p className="mt-6 leading-5 text-base text-gray-500 text-cente md:text-lg lg:max-w-3xl mx-auto">
					For over 50 years, businesses have come to depend on us for our
					expertise, quality, and customer service. We’ve worked with designers,
					architects, government agencies and business owners from a range of
					industries to transform the way they work.
				</p>
			</div>

			<div className="flex flex-wrap -mx-4 mt-20">
				<div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
					<div className="p-8 bg-white shadow rounded text-center">
						<h4 className="mb-6 text-2xl font-heading text-gray-900 ">
							Intelligent approach
						</h4>
						<img className="mx-auto" src={"/Intelligentapproach.svg"} />

						<p className="my-6 text-gray-600 text-center">
							We offer a range of managed and professional services which focus
							on matching customer requirements to innovative technologies,
							delivering truly positive business outcomes.
						</p>
					</div>
				</div>
				<div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8 lg:mb-0">
					<div className="p-8 shadow rounded text-center">
						<h4 className="mb-6 text-2xl text-gray-900 ">Amazing support</h4>
						<img className="mx-auto" src={"/amazingsupport.svg"} />

						<p className="my-6 text-gray-500 text-center">
							We strive to provide service that you will love. Our team does
							everything to ensure that you are completely satisfied and we’re
							always looking for ways to improve what we do.
						</p>
					</div>
				</div>
				<div className="w-full lg:w-1/3 px-4 lg:mb-0">
					<div className="p-8 bg-white shadow rounded text-center">
						<h4 className="mb-6 text-2xl  font-heading text-gray-900 ">
							Customer Satisfaction
						</h4>
						<img className="mx-auto" src={"/custionsatisfaction.svg"} />

						<p className="my-6 text-gray-600 text-center">
							Delighting our customers is what gets us out of bed in the
							morning. We measure every support interaction to ensure that our
							customers have received great support.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
